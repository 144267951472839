import { render, staticRenderFns } from "./GestionOrganisme.vue?vue&type=template&id=370d450c&scoped=true&"
import script from "./GestionOrganisme.vue?vue&type=script&lang=js&"
export * from "./GestionOrganisme.vue?vue&type=script&lang=js&"
import style0 from "./GestionOrganisme.vue?vue&type=style&index=0&id=370d450c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "370d450c",
  null
  
)

export default component.exports