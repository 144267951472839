<template>
  <div class="gestion-content">
    <div class="box-setting-header ">
      <div class="title">Gestion des organisations</div>
      <b-button
        size="sm"
        variant="success"
        class="config-btn ml-2"
        v-b-modal.AddOrganisme
        ><font-awesome-icon icon="plus" /> Ajouter une organisation</b-button
      >
      <b-modal
        id="AddOrganisme"
        ref="AddOrganisme"
        title="Ajouter une organisation"
        no-close-on-backdrop
        :hide-footer="true"
        :hide-header="true"
        @show="resetModal"
        @hidden="resetModal"
        modal-class="cutsom-modal-bootstrap"
      >
        <div class="hader mb-3">
          <div class="titleSetting">Ajouter une organisation</div>

          <div class="iconClose" @click.prevent="hideModal('AddOrganisme')">
            <font-awesome-icon icon="times" />
          </div>
        </div>
        <Card>
          <template v-slot:body>
            <form
              @submit.prevent="ajouterOrganisme"
              class="form-modal-custom-style"
            >
              <b-form-group
                label="Nom"
                label-for="Nom"
                class="input-modal-champ"
              >
                <b-form-input
                  autocomplete="off"
                  id="Nom"
                  v-model="organisationToAdd.name"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Coef CA"
                label-for="prix"
                class="input-modal-champ"
              >
                <b-form-input
                  autocomplete="off"
                  id="prix"
                  v-model="organisationToAdd.prix"
                ></b-form-input>
              </b-form-group>
              <div class="messageError mt-3">
                <div v-if="getErrorOrganisme" class="error">
                  {{ getErrorOrganisme }}
                </div>
              </div>
              <div class="actionModel">
                <b-button type="submit" class="button-valide-style ">
                  <span
                    >Enregistrer
                    <div v-if="getloagingOrganisme" class="loading ml-2">
                      <div class="spinner-border" role="status"></div></div
                  ></span>
                </b-button>
              </div>
            </form>
          </template>
        </Card>
      </b-modal>
    </div>
    <div class="ligne-box-setting-header"></div>

    <div>
      <b-list-group>
        <b-list-group-item id="sticky-line-list-group">
          <div class="information">
            <div class="data-th"><strong>Nom</strong></div>
            <div class="data-th"><strong>Coef CA</strong></div>
            <div class="data-th backgound-colo-white width-action-colone ">
              <strong></strong>
            </div>
          </div>
        </b-list-group-item>
        <b-list-group-item
          v-for="(Organisme, index) in getOrganisations"
          :key="index"
        >
          <div class="information">
            <div class="data-td">{{ Organisme.name }}</div>
            <div class="data-td">{{ Organisme.prix }}</div>
            <div class="data-td  backgound-colo-white width-action-colone ">
              <b-button
                class="succes-btn-organisme mr-2"
                size="sm"
                @click.prevent.stop="handleUpdate(Organisme)"
              >
                <font-awesome-icon icon="pencil-alt" />
              </b-button>
              <b-button
                size="sm"
                class="danger-btn-organisme "
                @click.prevent.stop="handleDelete(Organisme)"
              >
                <font-awesome-icon icon="trash-alt" />
              </b-button>
            </div>
          </div>
        </b-list-group-item>
        <b-modal
          id="ModelDeleteOrganisme"
          ref="ModelDeleteOrganisme"
          title="Supprimer une organisation"
          no-close-on-backdrop
          :hide-footer="true"
          @hidden="resetModal"
        >
          <div>
            <p>
              Êtes-vous sur de vouloir supprimer cette organisation
              <strong>
                {{ OrgaismeToDelete ? OrgaismeToDelete.name : '' }}</strong
              >
              ?
            </p>
          </div>
          <div class="form-modal-custom-style">
            <div class="messageError">
              <div v-if="getErrorOrganisme" class="error">
                {{ getErrorOrganisme }}
              </div>
            </div>
            <div class="actionModel">
              <b-button
                class="button-valide-style "
                @click="supprimerOrganisation"
              >
                <span>
                  Confirmer
                  <div v-if="getloagingOrganisme" class="loading ml-2">
                    <div class="spinner-border" role="status"></div></div
                ></span>
              </b-button>
            </div>
          </div>
        </b-modal>
        <b-modal
          id="ModelUpdateOrganisme"
          ref="ModelUpdateOrganisme"
          title="Modifier une organisation"
          no-close-on-backdrop
          :hide-footer="true"
          :hide-header="true"
          @hidden="resetModal"
          modal-class="cutsom-modal-bootstrap"
        >
          <div class="hader mb-3">
            <div class="titleSetting">Modifier une organisation</div>

            <div
              class="iconClose"
              @click.prevent="hideModal('ModelUpdateOrganisme')"
            >
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="updateOrganisme"
                v-if="OrganismeToUpdate"
                class="form-modal-custom-style"
              >
                <b-form-group
                  label="Nom"
                  label-for="Nom"
                  class="input-modal-champ"
                >
                  <b-form-input
                    autocomplete="off"
                    id="Nom"
                    v-model="OrganismeToUpdate.name"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Coef CA"
                  label-for="prix"
                  class="input-modal-champ"
                >
                  <b-form-input
                    autocomplete="off"
                    id="prix"
                    v-model="OrganismeToUpdate.prix"
                    required
                  ></b-form-input>
                </b-form-group>
                <div class="messageError">
                  <div v-if="getErrorOrganisme" class="error">
                    {{ getErrorOrganisme }}
                  </div>
                </div>
                <div class="actionModel mt-3">
                  <b-button class="button-valide-style " type="submit">
                    <span>
                      Modifier
                      <div v-if="getloagingOrganisme" class="loading ml-2">
                        <div class="spinner-border" role="status"></div></div
                    ></span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>
      </b-list-group>
      <div class="footer-style mt-2">
        <b-pagination
          v-model="page"
          :total-rows="getTotalRowOrganismes"
          :per-page="perPage"
          aria-controls="my-table"
          pills
          align="center"
          size="sm"
          @change="pagination"
          class="pagination-style"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  data() {
    return {
      page: 1,
      perPage: 10,
      year: null,
      organisationToAdd: {
        name: null,
        prix: '6.75'
      },
      OrgaismeToDelete: null,
      OrganismeToUpdate: null,
      ListWeek: [],
      data: [
        { id: 1, name: 'nom1', semaine: 's1', montant: '8.5', prix: '1200' },
        { id: 2, name: 'nom2', semaine: 's1', montant: '8.5', prix: '1200' },
        { id: 3, name: 'nom5' },
        { id: 4, name: 'nom7', semaine: 's30', montant: '8.5', prix: '1200' },
        { id: 5, name: 'nom8', semaine: 's20', montant: '8.5' }
      ]
    };
  },
  methods: {
    ...mapMutations(['SET_ERROR_ORGANISME']),
    ...mapActions([
      'fetchAllOrganisme',
      'addOrganisme',
      'updateOrganismes',
      'deleteOrganismes'
    ]),
    resetModal() {
      this.organisationToAdd.name = null;
      this.organisationToAdd.prix = '6.75';
      this.OrgaismeToDelete = null;
      this.OrganismeToUpdate = null;
      this.SET_ERROR_ORGANISME(null);
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
      this.SET_ERROR_ORGANISME(null);
    },
    async ajouterOrganisme() {
      const response = await this.addOrganisme(this.organisationToAdd);
      if (response) {
        this.hideModal('AddOrganisme');
      }
    },
    handleUpdate(Organisme) {
      this.OrganismeToUpdate = { ...Organisme };
      this.$refs['ModelUpdateOrganisme'].show();
    },
    handleDelete(Organisme) {
      this.OrgaismeToDelete = Organisme;
      this.$refs['ModelDeleteOrganisme'].show();
    },
    async supprimerOrganisation() {
      const response = await this.deleteOrganismes(this.OrgaismeToDelete);
      if (response) {
        this.hideModal('ModelDeleteOrganisme');
      }
    },
    async updateOrganisme() {
      const response = await this.updateOrganismes({
        OrganismeToUpdate: this.OrganismeToUpdate,
        annee: this.year
      });
      if (response) {
        this.hideModal('ModelUpdateOrganisme');
      }
    },
    pagination(paginate) {
      this.page = paginate;
      this.fetchAllOrganisme({
        page: this.page,
        annee: this.year
      });
    }
  },
  mounted() {
    var courantYear = moment().year();
    var totalWeek = moment().isoWeeksInYear(courantYear);
    this.year = courantYear;
    for (var week = 1; week <= totalWeek; week++) {
      this.ListWeek.push({ value: 's' + week, text: week });
    }
    this.fetchAllOrganisme({ page: this.page, annee: this.year });
  },
  computed: {
    ...mapGetters([
      'getOrganisations',
      'getloagingOrganisme',
      'getErrorOrganisme',
      'getTotalRowOrganismes'
    ])
  },
  components: {
    Card: () => import('../component/card')
  }
};
</script>
<style scoped lang="scss">
.gestion-content {
  padding: 15px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;
  .btn-style {
    border-radius: 50%;
  }
  .btn-style-color {
    background-color: #4d4bac;
    border: none;
  }
  .list-group {
    overflow-y: auto;
    height: calc(100vh - 260px);
    width: 60%;

    margin: auto;
    #sticky-line-list-group {
      position: sticky;
      top: 0;
      z-index: 7;
      background-color: #f0f1ff !important;
      border: none;
      padding: 0px;
    }

    .list-group-item + .list-group-item {
      background-color: #f6f5fb;
      border: 1px solid white;
      justify-content: space-between;
      display: flex;
      padding: 0px;
      &:hover,
      &:focus,
      &:active {
        background-color: #c5def1;
        .backgound-colo-white {
          background-color: #c5def1;
        }
        .succes-btn-organisme {
          background-color: #43af60 !important;
          border-radius: 24px;
        }
        .danger-btn-organisme {
          background-color: #ec2b2b !important;
          border-radius: 24px;
        }
      }
    }
    .list-group-item + .list-group-item:nth-child(even) {
      background-color: #fcfcff;
      &:hover,
      &:focus,
      &:active {
        .backgound-colo-white {
          background-color: #c5def1;
        }
        background-color: #c5def1;
        .succes-btn-organisme {
          background-color: #43af60 !important;
          border-radius: 24px;
        }
        .danger-btn-organisme {
          background-color: #ec2b2b !important;
          border-radius: 24px;
        }
      }
    }
    .information {
      justify-content: space-between;
      width: 100%;
      display: flex;
      .backgound-colo-white {
        background-color: #fff;
      }
      .width-action-colone {
        width: 20% !important;
      }
      .data-th {
        padding: 5px;
        border-right: 1px solid #fff;
        width: 40%;
        color: #2a2a2a;
        font-size: 11px;
        font-weight: 600 !important;
        font-family: 'Montserrat', sans-serif;
        text-align: center;
      }
      .data-td {
        padding: 4px;
        border-right: 1px solid #fff;
        width: 40%;
        line-height: 1.5;
        color: #2a2a2a;
        font-size: 11px;
        font-family: 'Montserrat', sans-serif;
        text-align: center;
      }
    }
  }
}
.list-group::-webkit-scrollbar {
  width: 30px;
  height: 30px;
}
.list-group::-webkit-scrollbar-thumb {
  background: #8d8cb7;
  border-radius: 25px;
  border: 12px solid transparent;
  background-clip: padding-box;
}
.list-group::-webkit-scrollbar-track {
  background: #f0eef8;
  border-radius: 30px;
  width: 15px;
  border-right: 10px solid white;
  border-left: 10px solid white;
  border-bottom: 10px solid white;
  border-top: 10px solid white;
}
</style>
